import React from 'react';
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import Link from "gatsby-link";
import {graphql} from "gatsby";
import MyPortableText from "../components/MyPortableText";
import {GatsbyImage} from "gatsby-plugin-image";
import {Parallax} from "react-scroll-parallax";
import Initiative from "../components/Initiative";
import ProgrammPunkt from "../components/ProgrammPunkt";
import NetzwerkPunkt from "../components/NetzwerkPunkt";

export const query = graphql`
    {
        allSanityNetzwerk {
            edges {
                node {
                    title
                    _rawIntrotext
                    treffen{
                        title
                        _rawModeration
                        zusatzinfo
                        _rawWith
                    }
                    
                }
            }
        }
    }
`;

const Coaching = ({data}) => {

    const netzwerk = data.allSanityNetzwerk.edges[0].node;

    return (
        <Layout newsletter={false} page={"initiativen"} backBtn={true}>
            <SEO title={netzwerk.title}/>
            <div className="">
                <div className="section__outer">
                    <div className="section__inner">
                        <div className="">
                            <Parallax translateY={[0, 20]} className="left netzwerkintrotext desktopblock">
                                <h2 className={"larger"}>{netzwerk.title}</h2>
                                <div className="programm__zusatztext netzwerkzusatztext">
                                    <MyPortableText value={netzwerk._rawIntrotext}></MyPortableText>
                                </div>
                            </Parallax>
                            <div className="left netzwerkintrotext mobile">
                                <h2 className={"larger"}>{netzwerk.title}</h2>
                                <div className="programm__zusatztext netzwerkzusatztext">
                                    <MyPortableText value={netzwerk._rawIntrotext}></MyPortableText>
                                </div>
                            </div>
                        </div>
                    </div>
                        <div className="section__outer">
                            <div className="section__inner">
                                <div className="coll__outer">
                                <div className="coll3">
                                    {netzwerk.treffen.slice(0, netzwerk.treffen.length / 2).map(el => (
                                        <NetzwerkPunkt title={el.title} mit={el._rawWith} moderation={el._rawModeration} zusatzinfo={el.zusatzinfo}></NetzwerkPunkt>
                                    ))}
                                </div>
                                <div className="coll3">
                                    {netzwerk.treffen.slice(netzwerk.treffen.length / 2, netzwerk.treffen.length).map(el => (
                                        <NetzwerkPunkt title={el.title} mit={el._rawWith} moderation={el._rawModeration} zusatzinfo={el.zusatzinfo}></NetzwerkPunkt>
                                    ))}
                                </div>
                                </div>
                    </div>
                        </div>
                    <div className="section__outer">
                        <div className="section__inner">
                            <h4 className={"uppercase"}>KONTAKT</h4>
                            <div className="separator"></div>
                            <div className="coll__outer flexspacebetween">
                                <div className="coll3">
                                    <p className={"smallwidth col3"}>
                                        Du hast Fragen zum Netzwerktreffen oder möchtest als Initiative mit dem <span className={"demibold"}>Markt der Zukunft</span> in Kontakt tretten? Dann schreib uns eine E-Mail an <a href="mailto:office@marktderzukunft.at" className="underline">office@marktderzukunft.at</a>.
                                    </p>
                                </div>
                                <div className="coll3 coaching desktop">
                                    <Link to={"/kontakt"} className="btn__inner light icon--small">
                                        Kontakt
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10.449" height="13.398" viewBox="0 0 10.449 13.398" style={{transform : "rotate(-90deg"}}>
                                            <g className={"svg-path-dark svg-path"} id="Group_47" data-name="Group 47" transform="translate(0.707)">
                                                <path id="Path_82" data-name="Path 82" d="M-852.781,666.193v10.725" transform="translate(857.298 -666.193)"/>
                                                <path id="Path_84" data-name="Path 84" d="M-857.731,675.45l4.517,4.517,4.517-4.517" transform="translate(857.731 -667.983)"/>
                                            </g>
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Coaching;
